.embla {
}

.emblaContainer {
  display: flex;
  gap: 1rem;
  touch-action: pan-x pinch-zoom;
  max-height: 500px;
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  .emblaContainer {
    height: 8rem;
    /* max-height: 25rem; */
    flex-direction: row;
  }

  .emblaSlide {
    min-width: 120px;
  }
}

.emblaSlide {
  position: relative;
  flex: 1;
  min-height: 0;
  aspect-ratio: 1.7;
}

/*****************************************/

.emblaHorizontal {
  margin-top: 1rem;
}
.emblaHorizontalViewport {
  overflow: hidden;
}

.emblaHorizontalContainer {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: -1rem;
}

.emblaHorizontalSlide {
  min-width: 0;
  flex: 0 0 calc(100% / 2);
  padding-left: 1rem;
  user-select: none;
}
@media (min-width: 480px) {
  .emblaHorizontalSlide {
    flex: 0 0 calc(100% / 3);
  }
}

@media (min-width: 768px) {
  .emblaHorizontalSlide {
    flex: 0 0 calc(100% / 4);
  }
}
