/* Homepage Blog Carousel Start CSS*/

.carousel_section {
  max-width: 100%;
  --slide-height: 375px;
  --slide-spacing: 20px;
  --slide-size: 100%;
  --slide-spacing-sm: 1.6rem;
  --slide-size-sm: 50%;
  --slide-spacing-lg: 1.25rem;
  --slide-size-lg: calc(100% / 4);
  margin: 0;
  width: 100%;
  margin-bottom: -20px;
}

.carousel_viewport {
  overflow: hidden;
}

.carousel_container {
  margin-top: 30px;
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

@media (min-width: 750px) {
  .carousel_container {
    margin-left: calc(var(--slide-spacing-sm) * -1);
  }
}

@media (min-width: 1200px) {
  .carousel_container {
    margin-left: calc(var(--slide-spacing-lg) * -1);
  }
}

.carousel_slide {
  min-width: 0;
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
}

@media (min-width: 750px) {
  .carousel_slide {
    flex: 0 0 var(--slide-size-sm);
    padding-left: var(--slide-spacing-sm);
  }
}

@media (min-width: 1200px) {
  .carousel_slide {
    flex: 0 0 var(--slide-size-lg);
    padding-left: var(--slide-spacing-lg);
  }
}

.carousel_slide_item {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 0;
  height: var(--slide-height);
  user-select: none;
  border: 1px solid #ced7d6;
  background-color: #ffffff;
}

.article_content {
  padding: 20px;
  background-color: #ffffff;
}

.image_item img {
  height: 182px;
  width: 100%;
  object-fit: cover;
}

.blog_carousel_heading {
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
  color: #00205b;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  min-height: 56px;
}

.article_content .read_more_btn {
  margin-top: 40px;
  display: flex;
  align-items: center;
  color: #00205b;
}
.right_arrow_icon {
  width: 18px;
  height: 18px;
  transform: rotate(-90deg);
  margin-top: 9px;
  margin-left: 5px;
}

.arrow_icon {
  width: 10px;
}

.carousel_controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}

.carousel_buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.carousel_button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel_button_next {
  font-size: 18px;
  width: 40px;
  height: 40px;
  border: 1px solid #666;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.carousel_button_prev {
  font-size: 18px;
  width: 40px;
  height: 40px;
  border: 1px solid #666;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.carousel_button:disabled {
  color: var(--detail-high-contrast);
  border: 1px solid #ccc;
}

.carousel_button__svg {
  width: 35%;
  height: 35%;
}

.carousel_dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}

.carousel_dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.carousel_dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
}

.carousel_dot_selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
  background-color: red;
}
.title_heading {
  color: #00205b;
  font-weight: 600;
}
.carousel_dot_controls {
  display: flex;
  justify-content: center;
  margin-top: 28px;
}
.carousel_dots_row {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.carousel_dot_item {
  width: 20px;
  height: 4px;
  background-color: #ccd2dece;
}
.carousel_dot_selected {
  background-color: #00205b;
}
@media only screen and (min-width: 568px) and (max-width: 936px) {
  .carousel_dot_controls {
    display: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .carousel_section {
    margin-bottom: 0;
  }
  .title_heading {
    font-size: 1.6rem;
  }
  .carousel_dot_controls {
    display: none;
  }
  .article_content .read_more_btn {
    margin-top: 10px;
  }
  .carousel_slide_item {
    height: auto;
  }
}
/* Homepage Blog Carousel End CSS*/
